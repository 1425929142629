import React, { lazy } from "react";
import { Redirect } from "react-router-dom";

export const routes = [
  {
    exact: true,
    path: "/",
    component: lazy(() => import("src/views/pages/Home")),
  },
  {
    exact: true,
    path: "/create",
    component: lazy(() => import("src/views/pages/Create")),
  },
  {
    exact: true,
    path: "/pools",
    component: lazy(() => import("src/views/pages/Pools")),
  },
  {
    exact: true,
    path: "/dashboard",
    component: lazy(() => import("src/views/pages/Dashboard")),
  },
  {
    exact: true,
    path: "/token-minter",
    component: lazy(() => import("src/views/pages/TokenMinter")),
  },
  {
    exact: true,
    path: "/services",
    component: lazy(() => import("src/views/pages/Services")),
  },
  {
    exact: true,
    path: "/pool-details",
    component: lazy(() => import("src/components/PoolDetails")),
  },
  {
    exact: true,
    path: "/aboutUs",
    component: lazy(() => import("src/views/pages/AboutUs/")),
  },
  {
    exact: true,
    path: "/terms",
    component: lazy(() => import("src/views/pages/TermsAndConditions/")),
  },
  {
    exact: true,
    path: "/support",
    component: lazy(() => import("src/views/pages/Support/")),
  },
  {
    exact: true,
    path: "/privacy",
    component: lazy(() => import("src/views/pages/PrivacyPolicy/")),
  },
  {
    exact: true,
    path: "/404",
    component: lazy(() => import("src/views/errors/NotFound")),
  },
  {
    exact: true,
    path: "/stake",
    component: lazy(() => import("src/views/pages/Stack/index")),
  },
  {
    component: () => <Redirect to="/404" />,
  },
];
