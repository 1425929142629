import React, { createContext, useState, useMemo, useEffect } from "react";
import Web3 from "web3";
import { ethers } from "ethers";
import {
  AbiDataETH,
  factoryContractAddressETH,
  FactoryContractAddressBNB,
  PresaleAbi,
  InfoAbi,
  FactoryContractAddressMatic,
  infoContractBnb,
  infoContractEth,
  infoContractMatic,
} from "src/context/Utils";
// import DscvrFactoryABI from "./Utils/DscvrFactoryABI.json";
import DscvrInfoABI from "./Utils/DscvrInfoABI.json";
// import PresaleAbi from "./Utils/PresaleAbi.json";
import axios from "axios";
import Apiconfigs from "src/ApiConfigs/API";
import { getContract } from "src/operations";
import multicall from "src/context/Utils/multicall";
import { useHistory } from "react-router";
export const AuthContext = createContext();
export default function AuthProvider(props) {
  const history = useHistory();
  const [isLogin, setIsLogin] = useState(
    window.localStorage.getItem("account") ? true : false
  );
  const [network, setNetwork] = useState(
    window.localStorage.getItem("network") === "Binance Chain"
      ? "Binance Chain"
      : window.localStorage.getItem("network") === "Matic"
      ? "Matic"
      : "Ethereum"
  );
  const [networkId, setNetworkId] = useState(
    window.localStorage.getItem("networkId")
      ? window.localStorage.getItem("networkId")
      : null
  );
  const [poolListData, setpoolListData] = useState([]);
  const [isUpdating, setIsUpdating] = useState(false);
  const [upcommingPoolList, setUpcommingPoolList] = useState([]);
  const [featuredPoolList, setFeaturedPoolList] = useState([]);
  const [userData, setUserData] = useState({
    networkID: "",
    currentAccount: window.localStorage.getItem("account"),
    myAccount: window.localStorage.getItem("account"),
  });

  const disconnectToWallet = () => {
    console.log("*************");
    window.localStorage.removeItem("account");
    setIsLogin(false);
  };

  const setTokenSession = (token) => {
    if (token) {
      sessionStorage.setItem("userToken", token);
    } else {
      sessionStorage.removeItem("userToken");
    }
  };

  const connectToWallet = async () => {
    window.localStorage.removeItem("account");
    window.localStorage.removeItem("network");
    try {
      if (window.ethereum) {
        const web3 = (window.web3 = new Web3(window.ethereum));

        await window.ethereum.enable();
        const accounts = await web3.eth.getAccounts();
        const networkId = await web3.eth.net.getId();
        setNetworkId(networkId);

        window.localStorage.setItem("account", accounts[0]);

        window.localStorage.setItem(
          "network",
          parseInt(networkId) === 97
            ? "Binance Chain"
            : parseInt(networkId) === 137
            ? "Matic"
            : "Ethereum"
        );
        setNetwork(
          parseInt(networkId) === 97
            ? "Binance Chain"
            : parseInt(networkId) === 137
            ? "Matic"
            : "Ethereum"
        );
        setUserData({
          ...userData,
          myAccount: accounts[0],
        });
        if (!isLogin) {
          setIsLogin(true);
        }
      } else {
        alert("Please login into Metamask");
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (userData.currentAccount) {
      connectWalletHandler(userData.currentAccount);
    }
  }, [userData.currentAccount]);
  const connectWalletHandler = async (walletAddress) => {
    try {
      const res = await axios.post(Apiconfigs.connectWallet, {
        walletAddress,
      });
      if (res.data.response_code === 200) {
        setTokenSession(res.data.result.token);
        setIsLogin(true);
      } else {
        setIsLogin(false);
        // deactivate();
      }
    } catch (error) {
      console.log("ERROR", error);
    }
  };

  useEffect(() => {
    (async function () {
      if (window.ethereum) {
        const web3 = (window.web3 = new Web3(window.ethereum));
        const networkId = await web3.eth.net.getId();
        setNetworkId(networkId);
      }
    })();
  }, []);

  //NETWORK CHANGE
  useMemo(() => {
    if (window.ethereum) {
      window.ethereum.on("networkChanged", async function (networkId) {
        window.localStorage.setItem("networkId", networkId);
        window.localStorage.setItem(
          "network",
          parseInt(networkId) === 97
            ? "Binance Chain"
            : parseInt(networkId) === 137
            ? "Matic"
            : "Ethereum"
        );
        // await connectToWallet();
        window.location.reload();
        // setNetworkId(networkId);
      });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    getDSCVRAddressHandler();
  }, [isLogin]); // eslint-disable-line react-hooks/exhaustive-deps

  // Upcomming Pool List
  useEffect(() => {
    if (poolListData.length > 0) {
      let result = poolListData;

      let currentTimeStamp = +new Date();
      let filterResult = result.filter((data) => {
        return data.data.openTime * 1000 > currentTimeStamp;
      });
      setUpcommingPoolList(filterResult.slice(0, 6));
    }
  }, [poolListData]); // eslint-disable-line react-hooks/exhaustive-deps

  //Featured Pool List
  useEffect(() => {
    if (poolListData.length > 0) {
      let result = poolListData;
      let currentTimeStamp = +new Date();
      let filterResult = result.filter((data) => {
        return data.data.closeTime * 1000 < currentTimeStamp;
      });
      setFeaturedPoolList(filterResult.slice(0, 6));
    }
  }, [poolListData]); // eslint-disable-line react-hooks/exhaustive-deps

  //ACCOUNT CHANGE
  useMemo(
    () =>
      window.ethereum &&
      window.ethereum.on("accountsChanged", async function (accounts) {
        if (accounts.length > 0) {
          await connectToWallet();
        } else {
          window.localStorage.removeItem("account");
          window.localStorage.removeItem("network");
          window.localStorage.removeItem("networkId");
          alert("Please Connect to wallet");
          setUserData({});
        }
      }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const getDSCVRAddressHandler = async () => {
    setpoolListData([]);
    // const infoContractAddressLocal = infoContractEth;
    const infoContractAddressLocal =
      network === "Ethereum"
        ? infoContractEth
        : network === "Matic"
        ? infoContractMatic
        : infoContractBnb;

    try {
      const contract = getContract(DscvrInfoABI, infoContractAddressLocal);
      await contract.methods
        .getPresalesCount()
        .call()
        .then(async (receipt) => {
          setIsUpdating(true);
          console.log("getPresalesCount", receipt);
          for (let i = receipt - 1; i >= 0; i--) {
            await getPresaleAddressHandler(infoContractAddressLocal, i);
          }
          setIsUpdating(false);
          console.log("I am done");
        });
    } catch (error) {
      console.error("error", error);
    }
  };

  // const getPresalesCountHandler = async (contractAddress) => {
  //   try {
  //     const contract = getContract(InfoAbi, contractAddress);
  //     await contract.methods
  //       .getPresalesCount()
  //       .call()
  //       .then(async (receipt) => {
  //         setIsUpdating(true);
  //         console.log("getPresalesCount", receipt);
  //         for (let i = receipt - 1; i >= 0; i--) {
  //           await getPresaleAddressHandler(contractAddress, i);
  //         }
  //         setIsUpdating(false);
  //         console.log("I am done");
  //       });
  //   } catch (error) {
  //     console.error("error", error);
  //   }
  // };

  const getPresaleAddressHandler = async (contractAddress, i) => {
    try {
      const contract = getContract(DscvrInfoABI, contractAddress);
      await contract.methods
        .getPresaleAddress(i)
        .call()
        .then(async (receipt) => {
          console.log("getPresaleAddress", receipt);
          await getPoolDataHandler(receipt, i);
        });
    } catch (error) {
      console.error("error***", error);
    }
  };

  const getPoolDataHandler = async (contractAddress, i) => {
    try {
      const calls = [
        // Balance of token in the LP contract
        {
          address: contractAddress,
          name: "closeTime",
        },
        {
          address: contractAddress,
          name: "totalInvestorsCount",
        },
        {
          address: contractAddress,
          name: "totalCollectedWei",
        },
        {
          address: contractAddress,
          name: "presaleCancelled",
        },
        {
          address: contractAddress,
          name: "ammLiquidityAdded",
        },
        {
          address: contractAddress,
          name: "hardCapInWei",
        },
        {
          address: contractAddress,
          name: "softCapInWei",
        },
        {
          address: contractAddress,
          name: "openTime",
        },
        {
          address: contractAddress,
          name: "ammLiquidityPercentageAllocation",
        },
        {
          address: contractAddress,
          name: "saleTitle",
        },
      ];

      const [
        closeTime,
        totalInvestorsCount,
        totalCollectedWei,
        presaleCancelled,
        uniLiquidityAdded,
        hardCapInWei,
        softCapInWei,
        openTime,
        uniLiquidityPercentageAllocation,
        saleTitle,
      ] = await multicall(PresaleAbi, calls);
      let obj = {
        closeTime: JSON.parse(closeTime),
        totalInvestorsCount: totalInvestorsCount.toString(),
        totalCollectedWei: ethers.utils.formatEther(
          totalCollectedWei.toString()
        ),
        presaleCancelled: JSON.parse(presaleCancelled),
        uniLiquidityAdded: JSON.parse(uniLiquidityAdded),
        hardCapInWei: ethers.utils.formatEther(hardCapInWei.toString()),
        softCapInWei: ethers.utils.formatEther(softCapInWei.toString()),
        openTime: JSON.parse(openTime),
        uniLiquidityPercentageAllocation:
          uniLiquidityPercentageAllocation.toString(),
        saleTitle: ethers.utils.parseBytes32String(saleTitle.toString()),
        presaleAddress: contractAddress,
      };
      console.log(obj);
      await updateState(i, obj);
    } catch (error) {
      console.error("error", error);
    }
  };

  const updateState = async (i, resultObj) => {
    setpoolListData((data) => [...data, { id: i, data: resultObj }]);
  };

  let data = {
    isLogin,
    userData,
    poolListData,
    network,
    isUpdating,
    networkId,
    upcommingPoolList,
    featuredPoolList,
    networkChnage: (data) => {
      window.localStorage.setItem("network", data);
      history.push("/");
      window.location.reload();
    },
    connectToWallet: connectToWallet,
    disconnectToWallet: disconnectToWallet,
  };

  return (
    <AuthContext.Provider value={data}>{props.children}</AuthContext.Provider>
  );
}
