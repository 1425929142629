// const url = "https://node-defi.mobiloitte.com/";
const url = "http://182.72.203.245:1873/";

const Apiconfigs = {
  connectWallet: `${url}api/v1/admin/connectWallet`,
  addUserAddress: `${url}api/v1/admin/addUserAddress`,
  listUserAddress: `${url}api/v1/admin/listUserAddress`,
};

export default Apiconfigs;
