import Abi from "./Abi.json";
// import Erc20Abi from './Erc20abi.json';
import TokenAbiData from "./TokenAbi.json";
import InfoAbiData from "./InfoAbi.json";
import PresaleAbiData from "./PresaleAbi.json";
import TokenContractABI from "./TokenContractABI.json";
import LiqValueCalcABI from "./LiqValueCalcABI.json";
// import FactoryBinanceABI from './FactoryBinanceABI.json';
// import InfoBinanceABI from './InfoBinanceABI.json';

export const PresaleAbi = PresaleAbiData;

export const TokenContractABIData = TokenContractABI;

export const InfoAbi = InfoAbiData;
// export const FactoryBinanceABIData = FactoryBinanceABI;
// export const InfoBinanceABIData = InfoBinanceABI;
export const TokenAbi = TokenAbiData;

export const AbiDataETH = Abi;
export const LiqValueCalcABIData = LiqValueCalcABI;

// export const Erc20AbiData = Erc20Abi;

export const factoryContractAddressETH =
  "0xc6162875a580326ABcD031372aCAcd960c8b03a4";

export const FactoryContractAddressBNB =
  "0xDbf73614dB5F5fAd13D918B7A4b238Afe9813062";

export const FactoryContractAddressMatic =
  "0x57594D7921dcbe64cc299E6dD99D5f7F72f7478F";

export const mulltiCallAddressEth =
  "0x23206C75D6D11497C2ACD2eb508d94CD0f05c8e9";
export const mulltiCallAddressBnb =
  "0x32F8647b2ff89B454170A25037D7643d219d6A19";
export const mulltiCallAddressMatic =
  "0x19E52c4E33dEDf1121165528712D9916f646b1eB";

export const infoContractBnb = "0xCD01Ef91CaB7408dF87ADb952f9e8A4247ca1872";

///yet to be replaced
export const infoContractEth = "0x0e070a0c4245B9A5551BB400dA68D6C5CF179B6d";
///

export const infoContractMatic = "0x16715558e4712764cECd5238FA669a7B81D303fe";

export const tokenRegistry = "0x8AA5E8033f93751D0e61715c653E7d7E6F3283A8";
export const factory = "0x105487A0752614C985ed0D62D117E09457A5F11E";
// export const presaleContractAddress =
//   '0xbcCD5aC28dDda42548680754a38549643ea69430';

// export const InfoContractAddress = '0x6518B027974099B9B164f59dd58BC4fe29601276';

// export const SafuInfoContractAddress =
//   '0x38F9a5e26f554Ce7a2fA43432C73DF207123be3F';

export function isUrlValid(userInput) {
  var res = userInput.match(
    /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g // eslint-disable-line no-useless-escape
  );
  if (res == null) return false;
  else return true;
}

export function isUrlValidTelegram(userInput) {
  var res = userInput.match(
    /https?:\/\/(t(elegram)?\.me|telegram\.org)\/([a-z0-9\_]{5,32})\/?/g // eslint-disable-line no-useless-escape
  );
  if (res == null) return false;
  else return true;
}
