import React from 'react';

export default function PageLoading() {
  return (
    <div
      style={{
        color: 'white',
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        padding: '25%',
      }}
    >
      Loading..
      <div className="loader"></div>
    </div>
  );
}
